.privacy-policy-page {
  &__main-container {
    font-size: 16px;
    margin: 0 auto;
    padding: 2em 4em;
  }

  &__content-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1em;
  }

  &__content-subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .4em;
    margin-top: 1em;
  }

  &__hints {
    box-sizing: border-box;
    left: 50%;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
}
